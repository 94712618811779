import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/home";
import Detail from "./pages/detail";
// import SiteMaintenance from "./pages/SiteMaintenance";
import CookiePolicy from "./pages/cookiepolicy";
import ProductsList from "./pages/ProductsList";
import Productspage from "./pages/Productspage";
import Promo from "./pages/Promo";
import Footer from "./components/footer";
import { useState, useEffect } from "react";
import DialogflowChatbot from "./components/dialogFlowChatBot";
import PromoCodePage from "./pages/PromoCodePage";

function App() {
  const [showCookieBanner, setShowCookieBanner] = useState(false); // Stato per il banner
  // const isMaintenance = false;

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowCookieBanner(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowCookieBanner(false);
    // logica per accettare i cookie
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("cookieConsent", "declined");
    setShowCookieBanner(false);
    // logica per rifiutare i cookie
  };

  const handleShowCookieBanner = () => {
    setShowCookieBanner(true);
  };

  // return isMaintenance ? (
  //   <SiteMaintenance />
  // ) : (
  return (
    <div className="w-screen">
      <Layout />

      {showCookieBanner && (
        <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 flex flex-col items-center z-30">
          <p className="mb-2 text-lg">
            Questo sito utilizza cookie per migliorare la tua esperienza.
            Continuando la navigazione acconsenti al loro uso.
          </p>
          <div className="flex space-x-4">
            <button
              onClick={handleAcceptCookies}
              className="bg-yellow-400 text-black py-2 px-4 rounded hover:bg-yellow-500"
            >
              Accetta
            </button>
            <button
              onClick={handleDeclineCookies}
              className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
            >
              Declina
            </button>
          </div>
          <div className="mt-2">
            <a
              href="/cookie-policy"
              className="text-blue-300 underline hover:text-blue-400"
            >
              Leggi di più
            </a>
          </div>
        </div>
      )}
      <DialogflowChatbot />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/productsList" element={<ProductsList />} />
        <Route path="/prodotti/:products" element={<Productspage />} />
        <Route path="/prodotti/:products/:slug" element={<Detail />} />
        <Route path="/cookiePolicy" element={<CookiePolicy />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/promoCodeGenerator" element={<PromoCodePage />} />
      </Routes>

      <Footer onShowCookieBanner={handleShowCookieBanner} />
    </div>
  );
}

export default App;
