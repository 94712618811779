import React from "react";
import MapComponent from "./MapComponent";

const JoinUs = () => {
  return (
    <section id="joinUs" className="flex flex-col items-center py-20 h-[800px] bg-gradient-to-r from-[#1b558f] to-[#05b4fc] text-white gap-20 z-10">
      <div className="font-bold text-5xl">RAGGIUNGICI</div>
      <MapComponent />
    </section>
  );
};

export default JoinUs;
