import React, { useState } from "react";

const PromoCodePage = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);

  // Funzione per generare un codice promozionale unico
  const generatePromoCode = () => {
    const code = "PROMO-" + Math.random().toString(36).substring(2, 10).toUpperCase();
    setPromoCode(code);
    setIsGenerated(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Genera il tuo Codice Promozionale!</h1>
        <p className="text-gray-600 mb-6">
          Clicca sul pulsante qui sotto per generare un codice promozionale unico da utilizzare con noi.
        </p>

        {!isGenerated ? (
          <button
            onClick={generatePromoCode}
            className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          >
            Genera Codice
          </button>
        ) : (
          <div className="mt-6">
            <p className="text-lg font-medium text-gray-700 mb-4">Il tuo codice promozionale è:</p>
            <div className="bg-gray-100 text-gray-800 py-2 px-4 rounded-lg font-mono text-lg mb-4">
              {promoCode}
            </div>
            <button
              onClick={() => navigator.clipboard.writeText(promoCode)}
              className="bg-green-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
            >
              Copia Codice
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCodePage;
